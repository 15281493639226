<template lang="">
  <div>
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <form-v-select
            ref="region"
            v-model="region"
            dataurl="region"
            col-label="3"
            label="Region"
            placeholder="Semua Region"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="8">
          <form-v-select
            ref="vendor"
            v-model="vendor"
            dataurl="vendor"
            col-label="3"
            label="Vendor"
            placeholder="Semua Vendor"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Tanggal"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua' },
                { value: 'created', text: 'Dibuat' },
                { value: 'submitted', text: 'Diajukan' },
                { value: 'approved', text: 'Disetujui' },
                { value: 'paid', text: 'Selesai' },
                { value: 'rejected', text: 'Ditolak' },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        v-if="renderComp"
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-view="false"
        :filter="filter"
        :title_modal="title_modal"
        :tombol_hide="true"
        :is-edit="false"
        :is-delete="false"
        :is-detail="false"
        :custom_filter="true"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1 float-right"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
        </template>
        <template v-slot:buttonaddon2>
          <b-button
            variant="primary"
            @click.prevent="showTambah"
          >
            <span class="text-nowrap">
              <feather-icon icon="PlusIcon" />
              Tambah
            </span>
          </b-button>
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status == 'created'"
            class="badge bg-secondary"
          >Dibuat</span>
          <span
            v-else-if="data.item.status == 'submitted'"
            class="badge bg-warning"
          >Diajukan</span>
          <span
            v-else-if="data.item.status == 'approved'"
            class="badge bg-info"
          >Disetujui</span>
          <span
            v-else-if="data.item.status == 'paid'"
            class="badge bg-success"
          >Selesai</span>
          <span
            v-else-if="data.item.status == 'rejected'"
            class="badge bg-danger"
          >Ditolak</span>
          <span v-else>-</span>
        </template>
        <template #cell(action)="data">
          <b-button
            variant="primary"
            size="sm"
            class="mr-0"
            @click="getDetail(data)"
          >
            <feather-icon
              icon="FolderIcon"
            />
          </b-button>
        </template>
      </basetable>
      <b-modal
        id="modal-center"
        ref="my-modal-tambah"
        :title="title_modal"
        centered
        hide-footer
      >
        <div class="form-group">
          <label>Status</label>
          <v-select
            v-model="itemNopol"
            label="name"
            placeholder="Pilih Nomor Klaim"
            :options="optionNopol"
            @input="changeSelectNopol"
          />
        </div>

        <div class="form-group mt-2 float-right">
          <b-button
            class
            variant="primary mr-1"
            @click="hideModal"
          >
            Batal
          </b-button>
          <b-button
            variant="success"
            @click.prevent="saveModal"
          >
            Tambah
          </b-button>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import vSelect from 'vue-select'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BInputGroup, BInputGroupAppend, BModal, BSpinner, BForm } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    basetable,
    vSelect,
    BCard,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol, 
    BInputGroup, 
    BInputGroupAppend, 
    BModal, 
    BSpinner, 
    BForm,
    FormVSelect,
    flatPickr
  },
  data() {
    return {
      dataurl:"/billing?filter[billingable_type]=App\\Model\\Claim",
      // dataurl:"/billing?filter[billingable_type]=App\Model\Claim",
      baseroute:"operasional-maintenance-item",
      title_modal: this.$route.meta.pageTitle,
      isModal:true,
      title:"Daftar Resiko Pribadi",
      renderComp : true,
      title_modal: null,
      showFilter: false,
      filter:[],
      region:null,
      vendor:null,
      status:null,
      date_start:"",
      date_end:"",
      itemNopol:[],
      optionNopol :[],
      dataTotal:null,
      fields: [
        { key: 'number', label: 'Nomor Penagihan', sortable: true },
        { key: 'billingable.number', label: 'Nomor Klaim', sortable: true },
        { key: 'billingable.vendor.number', label: 'Nomor SPB', sortable: true },
        { key: 'billingable.vendor.contract_subject', label: 'Judul Kontrak', sortable: true },
        { key: 'billingable.vendor.contract_number', label: 'Nomor Kontrak', sortable: true },
        { key: 'billingable.date', label: 'Tanggal Pengajuan', sortable: true },
        // { key: 'used_date', label: 'Tgl Pengambilan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      saveData:{},
    }
  },
  mounted(){
      this.getClaim()
  },
  methods:{
      showTambah(){
        this.title_modal = "Tambah"
        this.$refs['my-modal-tambah'].show()
      },
      changeSelectNopol(data){
        // console.log(data)
        if(data != null){
          this.saveData.id = data.id
          this.saveData.name = data.name
        }
      },
      hideModal(){
        this.$refs['my-modal-tambah'].hide()
      },
      saveModal(){
        let params = {
            id:this.saveData.id
        }
        this.$http.post('/claim/'+this.saveData.id+'/bill').then(()=>{
          this.successNotif()
        }).catch(err=>{
              this.errorSubmit(err)
            })
      },
      successNotif(){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Submit Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$refs['my-modal-tambah'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
      },
      errorSubmit(err){
        const msg = err.response.data.message
        this.$refs['my-modal-tambah'].hide()
        this.$bvToast.toast((msg)?msg:'Submit error', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
      },
      getClaim(){
        this.$http.get('/claim?filter[status]=approved').then(res=>{
          for(var nilai in res.data.data){
            this.optionNopol.push({
              id:res.data.data[nilai].id,
              name:res.data.data[nilai].number
            })
          }
        })
      },
      getDetail(data){
        console.log(data)
        this.$router.push({name:'own-risk-view', params:{id:data.item.id}})
      },
      show(){
        this.filter['filter[branch_id]'] = this.region?this.region.id:null
        this.filter['filter[spbs.vendor_id]'] = this.vendor?this.vendor.id:null
        this.filter['filter[billings.status]'] = this.status
        if(this.date_start != "" && this.date_end != "")
          this.filter['between[billings.date]']=this.date_start+","+this.date_end

        this.$refs.basetable.fetchData()
      },
      reset(){
        this.region = null
        this.vendor = null
        this.status = null
        this.date_start = ""
        this.date_end = ""
        this.filter['between[billings.date]'] = null
        this.show()
      },
  },
}
</script>
<style lang="">

</style>
